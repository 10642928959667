<script>
    import Scroll from './Scroll.svelte';
</script>

<div id="about">
    <div id="container">
        <h1>Who I Am</h1>
        <img src="/img/me.webp" alt="Me" id="me-img"/>
        <div id="info">
            <ul>
                <li>Bachelor of Innovation student in Computer Science at the University of Colorado Colorado Springs, graduating in May 2022 after a half-time semester. My goal in the meantime is to find a full-time job with a software company.</li>
                <li>Currently a systems administrator and web developer at Graham Investments.</li>
                <li>Built and maintained hundreds of workstations, servers, laptops, and other electronic devices for multiple companies and individuals.</li>
                <li>Experience with C, C++, C#, Java, Rust, Python, Javascript, PHP, Go, Ruby, SQL, Visual Basic, and GLSL.</li>
                <li>Game engine experience with Unreal Engine 4, Unity, and Godot.</li>
                <li>Other 2D/3D graphics development using SDL2, GLFW, WGPU, Luminance, Three.js, Python, Java, and Visual Basic.</li>
                <li>Fullstack web development experience with Ruby on Rails, Actix web, and PHP.</li>
                <li>Frontend experience with React, Svelte, JQuery, and even just vanilla Javascript.</li>
                <li>Linux VPS experience with Google Cloud Platform, MaxKVM, and Oracle Cloud.</li>
                <li>Other miscellaneous web technologies I have used include MySQL, PostgreSQL, Apache, NginX, and OpenLiteSpeed.</li>
            </ul>
        </div>
        <p>Please visit <a href="https://github.com/Perregrinne">my Github</a> to view the source code of many of these projects!</p>
    </div>
</div>
<Scroll/>

<style>
    #about {
        position: absolute;
        height: 100vh;
        top: 100vh;
        left: 0;
        right: 0;
        margin-top: 0;
        margin-bottom: 0;
        background-color: #444;
        text-align: center;
    }

    #container {
        text-align: center;
		padding: 1em;
		max-width: 50%;
		margin: 0 auto;
        color: #ccc;
    }

    #me-img {
        width: 156px;
        height: 208px;
        border-radius: 10px;
        object-fit: cover;
        object-position: 35% 20%;
    }

    #info {
        text-align: left;
    }

    li {
        margin: 0.5em 0;
    }

    a {
        color: #fff;
    }
    p {
        font-size: larger;
    }
</style>