<script>
    //Fontawesome:
	import Fa from 'svelte-fa';
	import { faGithub, faLinkedin } from '@fortawesome/free-brands-svg-icons';
</script>

<div class="social">
    <a href="https://github.com/Perregrinne"><Fa icon={ faGithub }/></a>
    <a href="https://www.linkedin.com/in/austin-jacob-653181164/"><Fa icon={ faLinkedin }/></a>
</div>

<style>
.social {
    text-align: right;
	position: fixed;
	right: 0;
	bottom: 0;
    font-size: 42px;
    width: 125px;
    height: 55px;
    border-radius: 50px 0 0 0;
    background-color: rgba(255,255,255,0.05);
    padding: 0 0.25em
}

a {
    padding: 0.25em 0.1em;
}
</style>