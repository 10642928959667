<script>
	//Threejs:
	import { onMount } from 'svelte';
	import { createScene } from "./scene";
	let el;
	onMount(() => {
	  createScene(el)
	});

	//Fontawesome:
	import Fa from 'svelte-fa';
	import { faEnvelope, faMobileAlt, faMapMarkerAlt, faUserCircle } from '@fortawesome/free-solid-svg-icons';

	//About section:
	import About from './About.svelte';

	//Social icons:
	import Social from './Social.svelte';

	//Scroll button:
	import Scroll from './Scroll.svelte';

	//Scroll-to:
	import { scrollto } from "svelte-scrollto";
  </script>

<main>
	<div id="head-container">
		<h1><img src="favicon.png" alt="icon"/><!--Fa icon={faUserCircle}/--> Austin Jacob</h1>
		<h2>Fullstack Web Developer</h2>
		<h2>2D and 3D Software Developer</h2>
		<h2>Systems Administrator</h2>
		<hr style="width: 20%; position: relative; left: 0; margin: 1em 1em 1em 0;">
		<h3>&nbsp;<Fa icon={faMobileAlt}/>&nbsp;&nbsp;(719) 900-1751</h3>
		<h3><Fa icon={faEnvelope}/>&nbsp;&nbsp;ajacob779@gmail.com</h3>
		<h3><Fa icon={faMapMarkerAlt}/>&nbsp;&nbsp;Monument, CO</h3>
	</div>
	<canvas bind:this={el} style="z-index: -1; position: absolute; right: 0; bottom: 0;"></canvas>
	<Scroll/>
	<About/>
	<Social/>
</main>

<style>
	main {
		text-align: center;
		padding: 1em;
	}

	#head-container {
		margin: 0;
		position: absolute;
  		top: 50%;
		-ms-transform: translateY(-50%);
  		transform: translateY(-50%);
	}

	h1 {
		color: rgb(250, 50, 75);
		text-transform: uppercase;
		font-size: 4em;
		font-weight: 100;
	}

	img {
		position: relative;
		bottom: -30px;
	}

	@media (min-width: 640px) {
		main {
			max-width: none;
		}
	}

	@media (max-width: 720px) {
		img {
			display: none;
		}

		main {
			text-align: left;
		}
	}
</style>