<script>
    //Fontawesome:
	import Fa from 'svelte-fa';
	import { faArrowCircleDown } from '@fortawesome/free-solid-svg-icons';

    import { scrollto } from "svelte-scrollto";
</script>

<div class="scroll-bttn"><a use:scrollto={'#about'}><Fa icon={ faArrowCircleDown } class="Fa"/></a></div>

<style>
    .scroll-bttn {
        font-size: 60px;
        position: absolute;
        bottom: 0;
        text-align: center;
        left: 0;
        right: 0;
    }

    :global(.Fa) {
        color: rgba(255,255,255,0.5);
    }

    :global(.Fa):hover {
        color: rgba(255,255,255,0.85);
        cursor: pointer;
    }
</style>